body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*@media only screen and (min-width: 1900px)  {*/
    /*body {*/
        /*transform: rotate(90deg);*/
    /*}*/

    /*#root {*/
        /*max-width: 100vh;*/
        /*overflow: auto;*/
    /*}*/
/*}*/

@font-face {
    font-family: 'MarkPro';
    src: url(/static/media/hinted-MarkPro-Bold.f75520e0.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro-Book';
    src: url(/static/media/hinted-MarkPro-Book.b21b3a59.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro';
    src: url(/static/media/hinted-MarkPro.84261f35.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro';
    src: url(/static/media/hinted-MarkPro-Medium.6632c5b7.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}



