/*@media only screen and (min-width: 1900px)  {*/
    /*body {*/
        /*transform: rotate(90deg);*/
    /*}*/

    /*#root {*/
        /*max-width: 100vh;*/
        /*overflow: auto;*/
    /*}*/
/*}*/

@font-face {
    font-family: 'MarkPro';
    src: url('fonts/hinted-MarkPro-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro-Book';
    src: url('fonts/hinted-MarkPro-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro';
    src: url('fonts/hinted-MarkPro.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MarkPro';
    src: url('fonts/hinted-MarkPro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}


